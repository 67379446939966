export default [
	{
		icon: '⏱',
		title: 'Time Coverter',
		path: 'https://savvytime.com/converter/ist-to-est-utc/',
		source: 'https://savvytime.com/converter/ist-to-est-utc/',
		description: 'TZ are hard! Tool to covert TZ',
	},
	{
		icon: '🛠',
		title: 'JSON Tool',
		path: 'https://codebeautify.org/jsonviewer',
		source: 'https://codebeautify.org/jsonviewer',
		description: 'JSON Tool',
	},
	{
		icon: '🧾',
		title: 'Explain Shell',
		path: 'https://explainshell.com/',
		source: 'https://explainshell.com/',
		description: 'Tool to understand shell command',
	},
	{
		icon: '🧨',
		title: 'Regex',
		source: 'https://regex101.com/',
		description: 'Regex builder, tester',
	},
	{
		icon: '⌗',
		title: 'Generate Fake Data',
		path: 'http://www.generatedata.com/',
		source: 'http://www.generatedata.com/',
		description: 'Generate Fake Data',
	},
	{
		icon: '⛏️',
		title: 'Text Compare',
		path: 'https://text-compare.com/',
		source: 'https://text-compare.com/',
		description: 'Text compare',
	},
	{
		icon: '📦',
		title: 'Github Comparison Tool',
		source: 'https://www.github.com/<rep_owner>/<repo>/compare/<base>...<target>',
		description: 'Github Comparison tool. Replace placeholder while using it.',
	},
	{
		icon: '★',
		title: 'Crontab',
		source: 'https://crontab.guru/',
		description: 'Know your crontab expression',
	},
	{
		icon: '✨',
		title: 'Can I upgrade Laravel yet?',
		source: 'https://laravelshift.com/can-i-upgrade-laravel',
		description: 'Tool to check dependency version compatible with Laravel',
	},
	{
		icon: '🌟',
		title: 'NodeJS Best Practice',
		source: 'https://github.com/goldbergyoni/nodebestpractices',
		description: 'Not tool but guide and centeral hub for reference',
	},
	{
		icon: '⏱',
		title: 'The official US Time',
		source: 'https://www.time.gov',
		description: 'US Time along with your system clock (which might be wrong)',
	},
	{
		icon: '🕵🏻‍♂️',
		title: 'Password Pusher',
		source: 'https://pwpush.com',
		description: 'Shared your password with trusted people with confidence',
	},
	{
		icon: '🚩',
		title: 'HTTP Status and Meaning',
		source: 'https://httpstatuses.com',
		description: 'HTTP Status and meaning',
	},
	{
		icon: '⚙️',
		title: 'Choose EC2 Instace Carefully!',
		source: 'https://instances.vantage.sh',
		description: 'Amazon EC2 comparison',
	},
	{
		icon: 'ᛣ',
		title: 'DPI / PPI Calculator',
		source: 'https://www.sven.de/dpi/',
		description: 'DPI / PPI Calculator',
	},
];
